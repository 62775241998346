.side_menu {
  max-width: 16rem;
  min-width: 16rem;
  z-index: 10;
  background-color: #1067c6;
  //   height: 100svh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  .logo {
    height: 64px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #0254ab;
    padding-left: 20px;
    position: sticky;
    top: 0;
    background-color: #1067c6;
  }
  .menu_item {
    margin-bottom: 70px;
    ul {
      padding: 0 5px;
      .main_link {
        padding-left: 15px;
        list-style-type: none;
        margin: 2px 0;
        cursor: pointer;
        &:hover {
          background-color: #3a8be1;
          border-radius: 6px;
        }
        .link_item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 15px;
          height: 48px;
          text-decoration: none;
          color: #fff;
          .arrow_flip {
            transform: rotate(180deg);
          }
        }
      }
      .activeMenu {
        background-color: #3a8be1;
        border-radius: 6px;
      }
    }
    .sub_menu {
      padding: 0;
      padding-left: 10px;
      .link {
        cursor: pointer;
        padding-left: 15px;
        list-style-type: none;
        margin: 2px 0;
        &:hover {
          background-color: #3a8be1;
          border-radius: 6px;
        }
        .link_item {
          display: flex;
          justify-content: start;
          align-items: center;
          height: 42px;
          text-decoration: none;
          color: #fff;
        }
      }
      .activeMenu {
        background-color: #3a8be1;
      }
    }
  }
  .logout {
    background-color: #1067c6;
    border-top: 1px solid #0254ab;
    position: fixed;
    bottom: 0;
    // width: 100%;
    width: calc(16rem);
    cursor: pointer;
    color: #fff;
    // margin: 5px;
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 15px;
    gap: 10px;
    img {
      width: 18px;
      height: 18px;
      color: #fff;
    }
    p {
      margin: 0;
    }
    &:hover {
      background-color: #3a8be1;
      // border-radius: 6px;
    }
  }
}
