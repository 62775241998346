@use "sass:math";

@mixin phone {
  @media (max-width: 576px) {
    @content;
  }
}
@mixin tab {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin medium-screen {
  @media (max-width: 991px) {
    @content;
  }
}
@mixin small-screen {
  @media (max-width:1200px){
    @content;
  }
}
@mixin large-screen {
  @media (max-width:1400px){
    @content;
  }
}
@mixin extra-large-screen {
  @media (max-width:1920px){
    @content;
  }
}