@import '../../styles/var';

.container {
    margin: auto;
    height: 100%;
    max-width: 1440px;
    padding: 0 40px;
}
@include large-screen{
    .container{
        max-width: 1300px;
        padding: 0 30px;
    }
}
@include small-screen{
    .container{
        // max-width: 900px;
    }
}