@font-face {
  font-family: "Arial";
  src: url("../../public/font/ARIAL.TTF");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Arial-medium";
  src: url("../../public/font/ArialMdm.ttf");
  font-style: normal;
  font-display: swap;
}
body {
  background: #f4f9fc !important;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

:global {
  .dropzone {
    border-radius: 6px;
    border: 1px dashed #888;
    padding: 12px;
    display: flex;
    height: 200px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .dropzone.active {
    border-color: red;
  }
  .rooms .STEP2 .css-ma3s50-MuiInputBase-input-MuiOutlinedInput-input {
    padding-right: 44px !important;
  }
  .payment .STEP2 .special_input input {
    padding-left: 55px;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 12px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

